//@flow

/* eslint-env browser */

import "./src/styles/index.scss";
import "@2po-dgp/components/build/esm/index.css";
import "analytics-transQA";
import "analytics-transGTM";

import { getMedium } from "@utils/analytics";
import { saveLocalStorageObject } from "@utils/LocalStorage";
import NavigationStackProvider from "@utils/NavigationStack/NavigationContext";

import { CHANNEL_ACADEMY, QUERY_PARAMETERS } from "./src/utils/Constants";
import {
    initialize as initializeSession,
    navigate as updateSession,
    update as updateSessionStore,
} from "./src/utils/session";

const getGTMEnvironmentString = () => {
    if (process.env.GATSBY_GTM_AUTH && process.env.GATSBY_GTM_ENV) {
        return `&gtm_auth=${process.env.GATSBY_GTM_AUTH}&gtm_preview=${process.env.GATSBY_GTM_ENV}`;
    }
    return "";
};

const gtmScriptContent = (gtmId: string) => `
(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.defer=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl+'${getGTMEnvironmentString()}';f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${gtmId}');`;

const gtmIframe = (gtmId: string) => `
<iframe src="https://www.googletagmanager.com/ns.html?id=${gtmId}${getGTMEnvironmentString()}"
height="0" width="0" style="display:none;visibility:hidden"></iframe>
`;

const HeadAcademyScript = () => `
(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push(
    {'gtm.start': new Date().getTime(),event:'gtm.js'}
    );var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl+ '&gtm_auth=f1SZvBc2JcEjQU6EnSl3rg&gtm_preview=env-11&gtm_cookies_win=x';f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-K7TBKKH');`;

const insertHeadScript = (gtmId: *) => {
    const addJS = () => {
        const s = document.createElement(`script`);
        s.type = `text/javascript`;
        s.innerHTML = gtmScriptContent(gtmId);
        document.getElementsByTagName(`head`)[0].appendChild(s);
    };

    addJS();
};

const insertHeadAcademy = () => {
    const addJS = () => {
        const s = document.createElement(`script`);
        s.type = `text/javascript`;
        s.innerHTML = HeadAcademyScript();
        document.getElementsByTagName(`head`)[0].appendChild(s);
    };

    addJS();
};

const insertIframe = (gtmId: *) => {
    const addJS = () => {
        const s = document.createElement(`noscript`);
        s.innerHTML = gtmIframe(gtmId);
        document.getElementsByTagName(`body`)[0].appendChild(s);
    };

    addJS();
};

const injectGTMSCripts = (gtmId: *) => {
    insertHeadScript(gtmId);
    insertIframe(gtmId);
};

const insertOptanonScript = () => {
    const onetrustID = process.env.GATSBY_ONETRUST_ID || "";
    const s = document.createElement("script");
    s.src = "https://cdn.cookielaw.org/scripttemplates/otSDKStub.js";
    s.type = "text/javascript";
    s.charset = "UTF-8";
    s.setAttribute("data-domain-script", onetrustID);
    document.getElementsByTagName("head")[0].appendChild(s);
    const s2 = document.createElement("script");
    s2.type = "text/javascript";
    s2.innerHTML = `function OptanonWrapper() { }`;
    document.getElementsByTagName("head")[0].appendChild(s2);
};

// Scan query parameters in this session for later retrieval
initializeSession();

// Update the session on route change
export const onRouteUpdate = () => {
    updateSession();
    //Re-initialize onetrust cookie policy button/list when they are found on the page
    const policy = document.getElementsByClassName("ot-sdk-show-settings");
    const policyList = document.getElementById("ot-sdk-cookie-policy");

    if (window?.OneTrust && (policy.length > 0 || policyList)) {
        window.OneTrust.initializeCookiePolicyHtml();
    }
};

export const onInitialClientRender = () => {
    insertOptanonScript();
    const gtmId = process.env.GATSBY_GTM_ID || "";
    if (CHANNEL_ACADEMY === process.env.GATSBY_BUILD_CHANNEL) {
        setTimeout(() => insertHeadAcademy(), 2500);
    }
    if (gtmId) {
        setTimeout(() => {
            injectGTMSCripts(gtmId);
        }, 3000);
    }
};

export const onClientEntry = () => {
    const referrer = document.referrer || window.frames.top.document.referrer;

    updateSessionStore(
        {
            referrer: {
                parsed: getMedium(referrer),
                raw: referrer,
            },
        },
        true,
    );
    let queryString = window.location.search;

    if (queryString && queryString.charAt(0) === "?")
        queryString = queryString.substring(1);

    saveLocalStorageObject(QUERY_PARAMETERS, queryString);
};

export const wrapRootElement = NavigationStackProvider;
