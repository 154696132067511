// @flow

import qs from "qs";

import { getPathName } from "../common";

type SessionReferrer = {
    raw: string,
    parsed: string,
};

type SessionEntry = {
    path?: string,
    search?: { [key: string]: string },
    referrer?: SessionReferrer,
};

type SessionCurrent = {
    path?: string,
    search?: { [key: string]: string },
};

type SessionPrevious = {
    path?: string,
};

type Session = {
    entry?: SessionEntry,
    current?: SessionCurrent,
    previous?: SessionPrevious,
    referrer?: SessionReferrer,
    medium?: string,
};

/**
 * When landing, store all querystring parameters in session storage
 * export modalities to expose them again.
 */

const STORE = "LANDING_QUERY_PARAMETERS";
const CLIENTSIDE = typeof window !== "undefined";

/**
 * Test if the store exists
 */
const exists = (initializing = false) => {
    const result = !!window.sessionStorage.getItem(STORE);

    if (!initializing && !result) {
        console.warn(
            "Trying to set the session store without it being initialized.",
        ); // eslint-disable-line no-console
    }

    return result;
};

/**
 * Initialized the session if none is detected already
 *
 * Scan QS Params and store them in session
 * Overwrites all previous data since it's a new landing
 */
export const initialize = () =>
    !exists(true) &&
    update(
        {
            entry: {
                path: getPathName(),
                search: qs.parse(window.location.search.slice(1)),
                referrer: {
                    raw: document.referrer,
                    parsed: document.referrer
                        ? new URL(document.referrer).hostname
                        : "",
                },
            },
        },
        true,
    );

/**
 * Set the current page details and move the current page to the previous page
 * slot
 */
export const navigate = () => {
    const currentSession = get();
    const newSearch = qs.parse(window.location.search.slice(1));

    const hasNewUtms = Object.keys(newSearch).some(key =>
        key.toLowerCase().includes("utm"),
    );

    update({
        previous: currentSession.current,
        current: {
            path: getPathName(),
            search: newSearch,
        },
        ...(hasNewUtms && {
            entry: {
                ...currentSession.entry,
                path: getPathName(),
                search: newSearch,
            },
        }),
    });
};

/**
 * Retrieve a list of stored QS Parameters
 */
export const get = (): Session => {
    if (!CLIENTSIDE) {
        return {};
    }

    try {
        const sessionStorage = JSON.parse(
            window.sessionStorage.getItem(STORE) || "{}",
        );
        return sessionStorage || {};
    } catch {
        return {};
    }
};

/**
 * Update the store
 */
export const update = (value: Object, force: boolean = false) =>
    CLIENTSIDE &&
    (exists() || force) &&
    window.sessionStorage.setItem(
        STORE,
        JSON.stringify({
            ...get(),
            ...value,
        }),
    );
