export const article = "ContentfulArticle";
export const articles = "articles";
export const career = "careerDetail";
export const textBlock = "ContentfulTextBlock";
export const displaySplit = "ContentfulDisplaySplit";
export const imageBlock = "ContentfulImageBlock";
export const collectionGrid = "ContentfulDisplayCollection";
export const heading = "ContentfulHeading";
export const quote = "ContentfulQuote";
export const callToAction = "ContentfulCallToAction";
export const link = "ContentfulLink";
export const linksBlock = "ContentfulLinkBlock";
export const categoryBar = "ContentfulCategoryBar";
export const mainCategory = "ContentfulMainCategory";
export const subCategory = "ContentfulSubCategory";
export const expert = "ContentfulExpert";
export const person = "ContentfulPerson";
export const testimonial = "ContentfulTestimonial";
export const pageTypePerson = "Person";
export const pageTypeArticle = "Article";
export const pageTypeCollection = "Display Collection";
export const pageTypeEdition = "Edition";
export const pageTypeDossier = "Dossier";
export const pageTypePodcast = "Podcast";
export const pageTypeOffice = "Office";
export const pageTypeSearch = "Search";
export const pageTypeMainCategory = "Main Category";
export const pageTypeSubCategory = "Sub Category";
export const pageTypeCompany = "Company";
export const pageTypeEvent = "Event";
export const pageTypeTransaction = "Transaction";
export const edition = "ContentfulEdition";
export const dossier = "ContentfulDossier";
export const footNote = "ContentfulFootNote";
export const videoBlock = "ContentfulVideoBlock";
export const articleConclusionGroup = "ContentfulArticleConclusionGroup";
export const displayBoxedContent = "ContentfulDisplayBoxedContent";
export const highlightValue = "ContentfulHighlightValue";
export const fund = "ContentfulFund";
export const landingPage = "ContentfulPageLanding";
export const experiencePage = "ContentfulPageExperiencePage";
export const page = "ContentfulPage";
export const introduction = "ContentfulIntroduction";
export const footer = "ContentfulFooter";
export const homePage = "ContentfulPageHome";
export const displayContentCard = "ContentfulDisplayContentCard";
export const displayExpandableCard = "ContentfulDisplayExpandableCard";
export const navigation = "ContentfulNavigation";
export const displayDownloadBlock = "ContentfulDisplayDownloadBlock";
export const marketSpecificAsset = "ContentfulMarketSpecificAsset";
export const marketSpecificContent = "ContentfulMarketSpecificContent";
export const podcast = "ContentfulPodcastShow";
export const displayHeader = "ContentfulDisplayHeader";
export const displayHero = "ContentfulDisplayHero";
export const asset = "ContentfulAsset";
export const offer = "ContentfulPromotionalOffer";
export const transaction = "ContentfulTransaction";
export const crossfadeImages = "ContentfulCrossFadeImages";
export const instagramBanner = "ContentfulInstagramBanner";
export const riskStatement = "ContentfulRiskStatement";
export const displaySection = "ContentfulDisplaySection";
export const displayBlock = "ContentfulDisplayBlock";
export const faq = "ContentfulFaq";
export const company = "ContentfulCompany";
export const document = "ContentfulDocument";
export const documentVersion = "ContentfulDocumentVersion";
export const iframe = "ContentfulIframe";
export const form = "ContentfulForm";
export const visionaryBanner = "ContentfulDisplayVisionaryBanner";
export const event = "ContentfulEvent";
export const table = "ContentfulDisplayTable";
export const personsSimpleList = "ContentfulPersonsSimpleList";
export const location = "ContentfulLocation";
export const documentTable = "ContentfulFundsTable";
export const displayEntry = "ContentfulDisplayEntry";
export const variantContainer = "ContentfulVariantContainer";
export const displaySticky = "ContentfulDisplaySticky";
export const contentSelector = "ContentfulAppropriateContentSelector";
export const campaign = "ContentfulCampaign";
export const placeholderContainer = "ContentfulPlaceholderContainer";
export const tabsContainer = "ContentfulDisplayTabContainer";
export const lunrImage = "lunrContentfulImage";
export const contentWidget = "ContentfulContentWidget";
export const textReplacementToken = "ContentfulTextReplacementToken";
