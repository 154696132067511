// @flow
import { get as getSession } from "@utils/session";

const searchEngines = [
    "google",
    "bing",
    "yahoo",
    "duckduckgo",
    "yandex",
    "startpage",
    "ecosia",
    "qwant",
    "aol",
    "ask.com",
    "dailymotion",
    "duckduckgo lite",
    "lilo",
    "mojeek",
    "swisscows",
    "search encrypt",
    "gigablast",
    "onesearch",
];

const socialMediaPlatforms = [
    "facebook",
    "twitter",
    "instagram",
    "linkedin",
    "pinterest",
    "snapchat",
    "youtube",
    "tiktok",
    "reddit",
    "tumblr",
    "flickr",
    "whatsapp",
    "telegram",
    "wechat",
    "discord",
    "viber",
    "myspace",
    "t.co",
    "lnkd.in",
    "x.com",
];

const extractDomainFromUrl = url => {
    try {
        const urlObj = new URL(url);
        return urlObj.hostname;
    } catch {
        return url;
    }
};

const internalDomains = [
    "degroofpetercam.com",
    process.env.GATSBY_SITE_URL
        ? extractDomainFromUrl(process.env.GATSBY_SITE_URL)
        : "",
    process.env.GATSBY_BUILD_CHANNEL || "",
].filter(Boolean);

// Helper function to extract hostname from URL or return the string if it's already a hostname
const extractHostname = url => {
    try {
        // Try to parse as URL
        const urlObj = new URL(url);
        return urlObj.hostname;
    } catch {
        // If parsing fails, assume it's already a hostname
        return url;
    }
};

// Helper function to check if a hostname matches any of the patterns
const matchesDomain = (url, patterns) => {
    const hostname = extractHostname(url);
    return patterns.some(pattern => {
        // Convert the pattern to a regex that matches the domain exactly or as a subdomain
        const regex = new RegExp(
            `^(?:[^.]+\\.)?${pattern
                .replace(/\./g, "\\.")
                .replace(/\s+/g, "-")}(?:\\.|$)`,
        );
        return regex.test(hostname);
    });
};

export const getUTMParameters = () => {
    const sessionData = getSession();

    if (sessionData.current && hasUTMParameters(sessionData.current.search)) {
        return sessionData.current.search;
    }

    if (sessionData.entry && hasUTMParameters(sessionData.entry.search)) {
        return sessionData.entry.search;
    }

    return {};
};

export const hasUTMParameters = (utmParams: Object) => {
    const hasParams = Object.values(utmParams).some(param => param !== "");
    return hasParams;
};

export const isOrganicSearchEngineReferral = (referrer: string) =>
    matchesDomain(referrer, searchEngines) ? "organic" : null;

export const isSocialMediaReferral = (referrer: string) =>
    matchesDomain(referrer, socialMediaPlatforms)
        ? "referral_social-media"
        : null;

// Helper function to check if a domain is internal
const isInternalDomain = url => {
    if (!url) return true; // Empty referrer means direct
    return matchesDomain(url, internalDomains);
};

export const getMedium = (referrer: string) => {
    // Empty referrer or internal domain should be considered direct
    if (!referrer || isInternalDomain(referrer)) {
        return "direct";
    }

    // Check for search engines and social media
    return (
        isOrganicSearchEngineReferral(referrer) ??
        isSocialMediaReferral(referrer) ??
        "referral_external"
    );
};

export const flattenKey = (key: string): string =>
    key
        .toLowerCase()
        .replace(/[-_][a-z]/g, group => group.slice(-1).toUpperCase());

const normalizeObject = obj => {
    const normalObject = {};

    for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
            const value = obj[key];

            if (Array.isArray(value)) {
                const firstFilledValue =
                    value.find(str => str.trim() !== "") || "";
                normalObject[key] = firstFilledValue;
            } else {
                normalObject[key] = value;
            }
        }
    }
    return normalObject;
};

export const getUtmTags = () => {
    const session = getSession();
    if (!session || typeof session !== "object") {
        return {
            utmSource: "",
            utmMedium: "",
            utmCampaign: "",
            utmContent: "",
            utmKeyword: "",
        };
    }

    // Create default UTM parameters object
    const defaultUtmParams = {
        utmSource: "",
        utmMedium: "",
        utmCampaign: "",
        utmContent: "",
        utmKeyword: "",
    };

    // Check current search params first
    if (session.current?.search) {
        const normalizedSearch = normalizeObject(session.current.search);
        const utmEntries = Object.entries(normalizedSearch).filter(([key]) =>
            key.toLowerCase().includes("utm"),
        );

        if (utmEntries.length > 0) {
            return {
                ...defaultUtmParams,
                ...utmEntries.reduce((acc, [key, value]) => {
                    if (value) {
                        const normalizedKey = flattenKey(key);
                        acc[normalizedKey] = value;
                    }
                    return acc;
                }, {}),
            };
        }
    }

    // If no current UTMs, check entry search params
    if (session.entry?.search) {
        const normalizedSearch = normalizeObject(session.entry.search);
        const utmEntries = Object.entries(normalizedSearch).filter(([key]) =>
            key.toLowerCase().includes("utm"),
        );

        if (utmEntries.length > 0) {
            return {
                ...defaultUtmParams,
                ...utmEntries.reduce((acc, [key, value]) => {
                    if (value) {
                        const normalizedKey = flattenKey(key);
                        acc[normalizedKey] = value;
                    }
                    return acc;
                }, {}),
            };
        }
    }

    return defaultUtmParams;
};

/**
 * Gets referrer keys from session
 * @returns {Object} The referrer keys
 */
export const getReferrerKeys = () => {
    const session = getSession();

    if (!session || typeof session !== "object") {
        return { utm_medium: "direct", utm_source: "" };
    }

    const utmTags = getUtmTags();

    if (
        Object.keys(utmTags).length > 0 &&
        Object.values(utmTags).some(v => v !== "")
    ) {
        // Convert camelCase UTM parameters to snake_case for external use
        return Object.entries(utmTags).reduce((acc, [key, value]) => {
            if (value !== "") {
                // Convert from camelCase to snake_case
                const snakeKey = key.replace(
                    /[A-Z]/g,
                    letter => `_${letter.toLowerCase()}`,
                );
                acc[snakeKey] = value;
            }
            return acc;
        }, {});
    }

    // If no UTMs, use the entry referrer (this is the original landing page referrer)
    const referrer = session.entry?.referrer;
    if (referrer?.parsed) {
        const medium = getMedium(referrer.parsed);
        return {
            utm_medium: medium,
            utm_source: referrer.raw || "",
        };
    }

    // Fallback to direct if no referrer info
    return {
        utm_medium: "direct",
        utm_source: "",
    };
};
